<style>
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
        margin: 0;
    }
</style>

<template>
  <div>
 <p class="title">分类</p>
    <Row :gutter="8">
        <i-col span="24">
          <RadioGroup v-model="type" type="button" size="small">
            <Radio
              v-for="item in confirmTypes"
              :key="item.id"
              :label="item.id"
              >{{ item.name }}</Radio
            >
          </RadioGroup>
        </i-col>
    </Row>

    <p class="title">账单状态</p>
    <Row :gutter="8">
        <i-col span="24">
          <RadioGroup v-model="status" type="button" size="small">
            <Radio
              v-for="item in confirmStatus"
              :key="item.id"
              :label="item.id"
              >{{ item.name }}</Radio
            >
          </RadioGroup>
        </i-col>
    </Row>

    <p class="title  p-t-5">作业类型</p>
    <Row :gutter="8">
        <i-col span="24">
          <div v-for="(item, index) in taskitemTypeArray" :key="index" class="p-l-10 p-1-10 m-b-2" @click="changeChecked('taskitemType',item.taskitemType)"
              :class="chkTaskitemTypes.includes(item.taskitemType)?'workplatform-radio-button-active':'workplatform-radio-button'">{{item.taskitemTypeText}}</div>
        </i-col>
    </Row>

    <p class="title  p-t-5">资源类型</p>
    <Row :gutter="8">
        <i-col span="24">
            <div v-for="(item, index) in resourceTypeArray" :key="index" class="p-l-10 p-1-10 m-b-2" @click="changeChecked('resourcetype',item.resourcetypeId)"
                :class="chkResourceTypes.includes(item.resourcetypeId)?'workplatform-radio-button-active':'workplatform-radio-button'">{{item.resourcetypeText}}</div>
        </i-col>
    </Row>

    <p class="title  p-t-5">记账周期 - 年度</p>
    <Row>
        <i-col span="24">
            <RadioGroup v-model="chooseYear" @on-change="handleChangeYear" size="small" type="button">
                <Radio label="-1">全部</Radio>
                <Radio v-for="year in years" :key="year" :label="year">{{year}}年</Radio>
            </RadioGroup>
        </i-col>
    </Row>
    <template v-if="chooseYear!=='-1'">
      <p class="title  p-t-5">记账周期 - 季度</p>
      <Row>
          <i-col span="24">
              <RadioGroup v-model="chooseSenson" @on-change="handleChangeSeason" size="small" type="button">
                  <Radio label="-1">全部</Radio>
                  <Radio v-for="season in seasons" :key="season" :label="season">{{season}}季度</Radio>
              </RadioGroup>
          </i-col>
      </Row>
      <p class="title  p-t-5">记账周期 - 月度</p>
      <Row>
          <i-col span="24">
              <RadioGroup v-model="chooseMonth" size="small" type="button">
                  <Radio label="-1">全部</Radio>
                  <Radio v-for="month in months" :key="month" :label="month">{{month}}月</Radio>
              </RadioGroup>
          </i-col>
      </Row>
    </template>

    <p class="title  p-t-5">记账周期 - 自定义</p>
    <Row :gutter="8">
        <i-col span="12" class="p-b-5">
          <DatePicker
            placeholder="开始时间"
            v-model="startDate"
            size="small"
            transfer
          ></DatePicker>
        </i-col>
        <i-col span="12" class="p-b-5">
          <DatePicker
            placeholder="结束时间"
            size="small"
            v-model="endDate"
            placement="bottom-end"
          ></DatePicker>
        </i-col>
    </Row>

    <p class="title  p-t-5">公司</p>
    <Row :gutter="8">
        <i-col span="24">
          <Select v-model="currentsettlementCompanyId" size="small" >
            <Option value="-1">全部</Option>
            <Option
              v-for="(company, index) in settlementcompanyArray"
              :key="index"
              :value="company.companyId"
              >{{ company.companyName }}</Option
            >
          </Select>
        </i-col>
    </Row>
    <p class="title  p-t-5">作业周期</p>
    <Row :gutter="8">
        <i-col span="12" class="p-b-5">
          <DatePicker
            placeholder="起始日期"
            type="date"
            v-model="startActionDate"
            size="small"
            transfer
          ></DatePicker>
        </i-col>
        <i-col span="12" class="p-b-5">
          <DatePicker
            placeholder="结束日期"
            type="date"
            v-model="endActionDate"
            size="small"
            placement="bottom-end"
          ></DatePicker>
        </i-col>
    </Row>

    <p class="title  p-t-5">计费区间</p>
    <Row :gutter="8">
        <i-col span="12" class="p-b-5">
          <i-input
            type="number"
            size="small"
            v-model="minCost"
            clearable
            placeholder="最低计费金额"
          ></i-input>
        </i-col>
        <i-col span="12" class="p-b-5">
          <i-input
            type="number"
            size="small"
            v-model="maxCost"
            clearable
            placeholder="最高计费金额"
          ></i-input>
        </i-col>
    </Row>

    <p class="title  p-t-5">关键字</p>
    <Row :gutter="8">
        <i-col span="18">
          <i-input
              type="text"
              size="small"
              v-model="keyword"
              clearable
              placeholder="如：客户、品牌、编码"
            ></i-input>
        </i-col>
        <i-col span="6">
          <Button
            type="primary"
            icon="ios-search"
            long
            size="small"
            :loading="loadSearch"
            @click="searchmaintenanceReconciliationList"
          >
            <span v-if="!loadSearch">搜索</span>
            <span v-else>加载中...</span>
          </Button>
        </i-col>
    </Row>
  </div>
</template>

<script>
import {
  gettaskitemtypelist,
  getresourcetypelist,
  getsettlements
  // getsuppliers
} from '@/api/msp/settlement'

import { ParseDate, lastMonthDate } from '@/utils/dateFormat'

export default {
  data () {
    return {
      confirmStatus: [
        { id: -1, name: '全部' },
        { id: 1, name: '已派单' },
        { id: 2, name: '执行中' },
        { id: 3, name: '已完成' },
        { id: 4, name: '已质检验收' }
      ],
      status: -1,
      confirmTypes: [
        { id: 1, name: '履约账单' },
        { id: 2, name: '运维账单' }
      ],
      type: 1,
      years: [],
      seasons: [1, 2, 3, 4],
      months: [],
      chooseYear: '-1',
      chooseSenson: '-1',
      chooseMonth: '-1',

      loadSearch: false,
      taskitemTypeArray: [], // 作业类型集合
      taskitemTypeId: null,
      chkTaskitemTypes: [-1],

      resourceTypeArray: [], // 资源类型集合
      resourceTypeId: null,
      chkResourceTypes: [-1],

      startDate: '',
      endDate: '',
      startActionDate: '',
      endActionDate: '',
      keyword: '',
      minCost: null,
      maxCost: null,

      settlementcompanyArray: [], // 结算方公司清单
      suppliercompanyArray: [], // 供应商清单
      currentsupplierCompanyId: 0,
      currentsettlementCompanyId: 0
    }
  },
  created () {
    this.inittaskitemTypeArray()
    this.initPageData()
  },
  methods: {
    initPageData () {
      const endYear = new Date().getFullYear()
      let startYear = 2020

      while (startYear <= endYear) {
        this.years.push(startYear)
        startYear++
      }
      this.handleChangeSeason()
    },
    handleChangeYear () {
      this.startDate = null
      this.endDate = null
      if (this.chooseYear === '-1') {
        this.chooseSenson = '-1'
        this.chooseMonth = '-1'
      }
    },
    handleChangeSeason () {
      this.startDate = null
      this.endDate = null
      this.chooseMonth = '-1'

      if (this.chooseSenson === '-1') {
        this.months = []
        for (let index = 1; index < 13; index++) {
          this.months.push(index)
        }
      } else {
        this.months = []
        for (let index = 1; index < 4; index++) {
          this.months.push(index + (parseInt(this.chooseSenson) - 1) * 3)
        }
      }
    },

    // 加载作业类型
    inittaskitemTypeArray () {
      const that = this
      that.taskitemTypeArray = []

      gettaskitemtypelist({
        settlementStatus: that.status === -1 ? null : that.status,
        taskMainTypeFlag: that.type
      }).then((res) => {
        that.taskitemTypeArray = res
        that.initresourceTypeArray()
      })
    },
    // 变更多选框选择
    changeChecked (key, value) {
      const that = this
      switch (key) {
        case 'taskitemType':
          if (value === -1) {
            that.chkTaskitemTypes = [-1]
          } else {
            const index = that.chkTaskitemTypes.indexOf(-1)
            if (index > -1) {
              that.chkTaskitemTypes.splice(index, 1)
            }

            if (that.chkTaskitemTypes.includes(value)) {
              const index1 = that.chkTaskitemTypes.indexOf(value)
              if (index1 > -1) {
                that.chkTaskitemTypes.splice(index1, 1)
              }
            } else {
              that.chkTaskitemTypes.push(value)
            }
          }
          that.initresourceTypeArray()
          break
        case 'resourcetype':
          if (value === -1) {
            that.chkResourceTypes = [-1]
          } else {
            const index = that.chkResourceTypes.indexOf(-1)
            if (index > -1) {
              that.chkResourceTypes.splice(index, 1)
            }

            if (that.chkResourceTypes.includes(value)) {
              const index1 = that.chkResourceTypes.indexOf(value)
              if (index1 > -1) {
                that.chkResourceTypes.splice(index1, 1)
              }
            } else {
              that.chkResourceTypes.push(value)
            }
          }
          break
      }
    },
    // 加载资源类型
    initresourceTypeArray () {
      const that = this
      that.resourceTypeArray = []
      let taskitemTypeStr = null
      if (that.chkTaskitemTypes.length && !that.chkTaskitemTypes.includes(-1)) {
        taskitemTypeStr = that.chkTaskitemTypes.toString()
      }
      getresourcetypelist({
        settlementStatus: that.status === -1 ? null : that.status,
        taskMainTypeFlag: that.type,
        taskitemTypes: taskitemTypeStr// that.taskitemTypeId === -1 ? null : that.taskitemTypeId
      }).then((res) => {
        that.resourceTypeArray = res
        that.resourceTypeId = that.resourceTypeArray[0].resourcetypeId

        this.initsettlementCompanyArray()
      })
    },
    // 获取结算方公司列表
    initsettlementCompanyArray () {
      getsettlements({ }).then((res) => {
        this.settlementcompanyArray = res
        if (this.settlementcompanyArray.length) {
          this.currentsettlementCompanyId = this.settlementcompanyArray[0].companyId
        }
        this.searchmaintenanceReconciliationList()
      })
    },

    // 搜索列表
    searchmaintenanceReconciliationList () {
      let searchStartDate = ''
      let searchEndDate = ''

      if (this.startDate || this.endDate) {
        searchStartDate = this.startDate ? ParseDate(this.startDate) : ''
        searchEndDate = this.endDate ? ParseDate(this.endDate) : ''
      } else {
        if (this.chooseMonth !== '-1') { // 取月度
          const flag = '-' + (this.chooseMonth < 10 ? '0' : '')
          searchStartDate = this.chooseYear + flag + this.chooseMonth + '-01'
          searchEndDate = lastMonthDate(this.chooseYear, this.chooseMonth)
        } else if (this.chooseSenson !== '-1') { // 取季度
          const startMonth = 1 + (parseInt(this.chooseSenson) - 1) * 3
          const startYear = this.chooseYear + '-' + (startMonth < 10 ? '0' : '')
          searchStartDate = startYear + startMonth + '-01'
          searchEndDate = lastMonthDate(this.chooseYear, startMonth + 2)
        } else if (this.chooseYear !== '-1') { // 取年度
          searchStartDate = this.chooseYear + '-01-01'
          searchEndDate = this.chooseYear + '-12-31'
        } else {
          searchStartDate = ''
          searchEndDate = ''
        }
      }

      var query = {
        settlementCompanyId: this.currentsettlementCompanyId,
        supplierCompanyId: this.companyId,
        status: this.status, // 对账状态
        taskMainTypeFlag: this.type,
        taskitemType: this.taskitemTypeId,
        resourcetypeId: this.resourceTypeId,
        startDate: searchStartDate,
        endDate: searchEndDate
      }

      if (this.startActionDate) {
        query.startActionDate = ParseDate(this.startActionDate)
      }
      if (this.endActionDate) {
        query.endActionDate = ParseDate(this.endActionDate)
      }
      if (this.keyword) {
        query.keyword = this.keyword
      }
      if (this.minCost || this.minCost === 0) {
        query.minCost = this.minCost
      }
      if (this.maxCost || this.maxCost === 0) {
        query.maxCost = this.maxCost
      }
      if (this.chkTaskitemTypes.length && !this.chkTaskitemTypes.includes(-1)) {
        query.taskitemTypes = this.chkTaskitemTypes.toString()
      }
      if (this.chkResourceTypes.length && !this.chkResourceTypes.includes(-1)) {
        query.resourcetypeIds = this.chkResourceTypes.toString()
      }

      // if (this.executeStatus !== -1) { query.executeStatus = this.executeStatus }
      this.$store.commit(
        'set_reconciliationDemo_maintenanceReconciliationQuery',
        query
      )
      this.$store.commit('set_reconciliationDemo_update', new Date())
    }
  },
  computed: {
    companyId () {
      return this.$store.getters.token.userInfo.companyId
    },
    publisherId () {
      return this.$store.getters.token.userInfo.publisherId
    }
  },
  watch: {
    status () {
      this.inittaskitemTypeArray()
    },
    type () {
      this.inittaskitemTypeArray()
    }
  }
}
</script>
